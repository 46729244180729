
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import InputSwitch from 'primevue/inputswitch';
import Button from "primevue/button";
import useVuelidate, { ErrorObject } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import MessageBox from "@/components/MessageBox.vue";

// Services
import ScanService from "@/services/ScanService";
import WorkOrderService from "@/services/WorkOrderService";
import EmployeeTimeService from "@/services/EmployeeTimeService";
import { EndJobStockTransferModes } from "@/types/controls/workOrder";
import Utils from "@/utility/utils";
import InputEmployeeID from "../InputEmployeeID.vue";

const scanService = new ScanService(process.env.VUE_APP_ABSTRACTION_API);
const workOrderService = new WorkOrderService(
  process.env.VUE_APP_ABSTRACTION_API
);

const employeeTimeService = new EmployeeTimeService(
  process.env.VUE_APP_ABSTRACTION_API
);

export default defineComponent({
  name: "Details",
  components: {
    InputText,
    InputSwitch,
    Button,
    MessageBox,
    InputEmployeeID,
  },
  data() {
    return {
      endJobPayload: {
        employee_id: "",
        function_input: "",
        work_order: "",
        operation: "",
        move_to_next_operation: "",
        destination_operation: "",
        quantity_to_move: "",
      },
      functionDesc: "",
      work_center: "",
      cardHeight: "h-20rem",
      loadEndJob: false,
      direct_item: false,
      errorMessage: "",
      showErrorDialog: false,
      focusRefName: "",
      operation_balance: "",
      employee_time: {} as any,
      rowClass:
        "p-field p-col col-12 m-0 p-0 pb-1 flex justify-content-center r-mono",
    };
  },
  async created() {
    await this.fetchLaborControl();
    await this.fetchWorkOrderControl();
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      employee_id: {
        required: helpers.withMessage("Employee ID", required),
      },
    };
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
      getLaborFunctionTypes: "laborControl/getFunctionTypes",
      getEndJobStockTransferMode: "woControl/getEndJobStockTransferMode"
    }),
    showNextOperation() : boolean {
      const showNextOperation = +this.getEndJobStockTransferMode === EndJobStockTransferModes.OPTIONAL_NEXT_OPERATION
      return showNextOperation;
    },
    showDestinationOperation() : boolean {
      const showDestinationOperation = +this.getEndJobStockTransferMode === EndJobStockTransferModes.OPTIONAL_OPERATION;
      return showDestinationOperation;
    },
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      addSalesOrders: "salesOrder/addSalesOrders",
      removeSalesOrders: "salesOrder/removeSalesOrders",
      clearSalesOrders: "salesOrder/clearSalesOrders",
      fetchLaborControl: "laborControl/fetchLaborControl",
      fetchWorkOrderControl: "woControl/fetchWorkOrderControl",
    }),
    clearAll() {
      this.endJobPayload.employee_id = "";
      this.endJobPayload.function_input = "";
      this.functionDesc = "";
      this.work_center = "";
      this.endJobPayload.work_order = "";
      this.endJobPayload.operation = "";
      this.direct_item = false;
      this.endJobPayload.move_to_next_operation = "";
      this.endJobPayload.destination_operation = "";
      this.endJobPayload.quantity_to_move = "";
      this.operation_balance = "";
    },
    focusInput(refName: string) {
      if (refName) {
        if (refName === "employee_id") {
          (this.$refs[refName] as any).focus();
        } else {
          const ref = this.$refs[refName] as any;
          if (ref.inputId) {
            const element = document.getElementById(ref.inputId) as HTMLElement;
            if (element) {
              element.focus();
            }
          } else {
            ref.$el.focus();
            if (ref.$el.select) {
              ref.$el.select();
            }
          }
        }
      }
    },
    endJob() {
      var err = null;
      if (!this.endJobPayload.employee_id) {
        this.handleErrorAndFocus("No employee entered", "employee_id");
        return;
      } else if (!this.endJobPayload.function_input) {
        this.handleErrorAndFocus("No function entered.", "function_input");
        return;
      }

      if (this.direct_item) {
        if (!this.endJobPayload.work_order) {
          this.handleErrorAndFocus("No work order entered.", "work_order");
          return;
        } else if (!this.endJobPayload.operation) {
          this.handleErrorAndFocus("No operation entered.", "operation");
          return;
        }
      }

      this.loadEndJob = true;
      scanService
        .postEndJob(this.endJobPayload)
        .then((resp: any) => {
          const notification = {
            dialog: true,
            type: "success",
            message: resp?.message || "Successfully ended job.",
          };
          this.addNotification(notification, { root: true });
          this.clearAll();
        })
        .catch((err) => {
          this.handleErrorAndFocus(
            Utils.parseErrorMessage(err).toString() || "Could not process end job.",
            "employee_id"
          );
        })
        .finally(() => {
          this.loadEndJob = false;
        });
    },
    handleFetchEmployee() {
      employeeTimeService
        .getEmployeeTimeByID(
          this.getClient,
          this.endJobPayload.employee_id,
          "clock_in_date clock_in_time clock_out_date ip_start_date ip_wo_id ip_function ip_oper",
          ""
        )
        .then((emptime_resp: any) => {
          if (emptime_resp.emptime_items) {
            const emptime = emptime_resp.emptime_items[0];
            this.employee_time = emptime;

            if(emptime.ip_start_date_items && emptime.ip_start_date_items.length > 0) {
              const ip = emptime.ip_start_date_items[0];

              if (ip) {
                this.endJobPayload.function_input = ip.ip_function;
                this.endJobPayload.work_order = ip.ip_wo_id;
                this.endJobPayload.operation = ip.ip_oper;

                if (ip.ip_function) {
                  this.handleFunction();
                }
                if (ip.ip_wo_id) {
                  this.handleWorkOrder();
                }
                if (ip.ip_oper) {
                  this.handleOperation();
                }
              }
            }
            this.focusInput("function_input");
          }
        })
        .catch((err) => {
          this.handleErrorAndFocus(err.toString(), "employee_id");
        });
    },
    handleFunction() {
      if (this.endJobPayload.function_input) {
        this.endJobPayload.function_input =
          this.endJobPayload.function_input.toUpperCase();
        if (this.employee_time.ip_start_date_items) {
          const ip_item = this.employee_time.ip_start_date_items.find(
            (item: any) => {
              return item.ip_function.toUpperCase() === this.endJobPayload.function_input;
            }
          );

          if (ip_item) {
            const labor_control = this.getLaborFunctionTypes.find(
              (item: any) => {
                return item.function_type === this.endJobPayload.function_input;
              }
            );

            if (labor_control) {
              this.endJobPayload.work_order = ip_item.ip_wo_id ? ip_item.ip_wo_id : "";
              this.endJobPayload.operation = ip_item.ip_oper ? ip_item.ip_oper : "";
              this.work_center = "";
              this.functionDesc = labor_control.function_description;
              this.focusInput("work_order");
            }
          }
        }
      } else {
        this.handleErrorAndFocus(
          "A function must be entered",
          "function_input"
        );
      }
    },
    handleWorkOrder() {
      this.direct_item = false;
      const function_type = this.getLaborFunctionTypes.find(
        (item: any) => {
          return item.function_type.toUpperCase() === this.endJobPayload.function_input;
        }
      );

      if (function_type && !function_type.function_account_number) {
        this.direct_item = true;
      }

      if (this.employee_time.ip_start_date_items) {
        const ip = this.employee_time.ip_start_date_items.find((item: any) => {
          return item.ip_wo_id === this.endJobPayload.work_order;
        });

        if (ip) {
          if (ip.ip_function.toUpperCase() === this.endJobPayload.function_input) {
            this.endJobPayload.operation = ip.ip_oper;
            this.work_center = ip.ip_work_center;
            this.focusInput("operation");
          }
        }
      }
    },
    handleOperation() {
      if (!this.direct_item) {
        this.endJobPayload.operation = "";
        this.work_center = "";
      }

      if (this.endJobPayload.work_order) {
        workOrderService
          .getWorkOrderById(
            this.getClient,
            this.endJobPayload.work_order,
            "",
            ""
          )
          .then((resp: any) => {
            const oper_items = resp.oper_items;
            if (oper_items) {
              const operation = oper_items.find((item: any) => {
                return (item.oper === this.endJobPayload.operation);
              });

              if (operation) {
                this.work_center = operation.work_center;
                this.operation_balance = operation.curr_oper_qty;
                const ip = this.employee_time.ip_start_date_items.find(
                  (item: any) => {
                    return (
                      item.ip_wo_id === this.endJobPayload.work_order &&
                      item.ip_oper === this.endJobPayload.operation
                    );
                  }
                );
              }
            }
          });
      }
      if (this.showNextOperation) {
        this.focusInput("move_to_next_operation");
      } else if (this.showDestinationOperation) {
        this.focusInput("destination_operation");
      }
    },
    handleErrorAndFocus(message: string, ref: string) {
      this.errorMessage = message;
      this.showErrorDialog = true;
      this.focusRefName = ref;
    },
    clickConfirmErrorDialog() {
      this.showErrorDialog = false;
      this.focusInput(this.focusRefName);
    },
  },
  mounted() {
    this.focusInput("employee_id");
    this.clearAll();
  },
});
